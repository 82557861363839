import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Послуга прокату автомобілів Peakusad
			</title>
			<meta name={"description"} content={"Відкрийте незвідані дороги з Peakusad."} />
			<meta property={"og:title"} content={"Головна | Послуга прокату автомобілів Peakusad"} />
			<meta property={"og:description"} content={"Відкрийте незвідані дороги з Peakusad."} />
			<meta property={"og:image"} content={"https://peakusad.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://peakusad.com/img/6677615.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://peakusad.com/img/6677615.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://peakusad.com/img/6677615.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://peakusad.com/img/6677615.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://peakusad.com/img/6677615.png"} />
			<meta name={"msapplication-TileImage"} content={"https://peakusad.com/img/6677615.png"} />
		</Helmet>
		<Components.Header />
		<Section
			min-height="100vh"
			lg-padding="120px 0 88px 0"
			md-padding="140px 0 96px 0"
			sm-padding="60px 0 96px 0"
			quarkly-title="Hero-23"
			padding="88px 0 88px 0"
			background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.5) 67.71%,rgba(4, 8, 12, 0.6) 100%),url(https://peakusad.com/img/1.jpg) center center/cover no-repeat"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-flex-direction="column"
				display="flex"
				lg-width="100%"
				align-items="flex-start"
				lg-align-items="center"
				lg-justify-content="center"
				width="100%"
				flex-direction="row"
			/>
			<Box
				margin="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-justify-content="center"
				display="flex"
				lg-align-items="center"
				align-items="flex-end"
				lg-width="100%"
				padding="0px 0px 0px 0px"
				justify-content="space-between"
				lg-flex-direction="column"
				width="100%"
				flex-direction="row"
			>
				<Text
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="25%"
					md-width="100%"
					margin="0px 0px 0px 0px"
					lg-width="100%"
					color="--light"
					font="--headline2"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					lg-margin="0px 0px 20px 0px"
					lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Peakusad
				</Text>
				<Text
					color="#FFFFFF"
					font="--base"
					width="25%"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
				>
					Вирушайте у свою наступну подорож з ідеальним компаньйоном на колесах. Служба прокату автомобілів Peakusad пропонує вам свободу подорожувати у власному темпі з автопарком, який підходить для будь-якої пригоди, ділової поїздки або сімейного відпочинку.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 33px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="#111"
					font="--headline2"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Відкрийте для себе відмінності Peakusad
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="stretch"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="1fr"
				justify-items="center"
			>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 35px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 35px 35px"
					border-style="solid"
					border-width="1px"
					border-color="--color-primary"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
					Різноманітний автопарк
					</Text>
					<Text
						margin="0px"
						color="--greyD2"
						text-align="center"
						font="--base"
						flex="1 1 auto"
					>
						Від спортивних компактних до сімейних позашляховиків - наш вибір підходить для будь-якого випадку.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 35px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 35px 35px"
					position="relative"
					border-style="solid"
					border-width="1px"
					border-color="--color-primary"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
					Допомога на дорозі
					</Text>
					<Text
						margin="0px"
						color="--greyD2"
						text-align="center"
						font="--base"
						flex="1 1 auto"
					>
						Душевний спокій входить в стандартну комплектацію з цілодобовою підтримкою в разі несподіваних поворотів.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="48px 40px 35px 40px"
					background="--color-light"
					border-radius="24px"
					align-items="center"
					justify-content="flex-start"
					flex-direction="column"
					lg-padding="48px 35px 35px 35px"
					border-style="solid"
					border-width="1px"
					border-color="--color-primary"
				>
					<Text margin="0px 0px 24px 0px" font="--headline3">
					Гнучка оренда
					</Text>
					<Text
						margin="0px"
						color="--greyD2"
						text-align="center"
						font="--base"
						flex="1 1 auto"
					>
						Незалежно від того, чи це одноденна поїздка, чи довгострокова оренда, ми підлаштовуємося під ваш графік.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="0px 0px 624px 0px"
						display="flex"
						flex-direction="column"
						background="url(https://peakusad.com/img/2.jpg) center/cover"
						width="100%"
						lg-padding="0px 0px 248px 0px"
						sm-padding="0px 0px 380px 0px"
					/>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://peakusad.com/img/3.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://peakusad.com/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://peakusad.com/img/5.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});